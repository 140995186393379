// headersMap.js

import { Tooltip } from "react-tooltip";
import Cart from "./Cart";
import ProductPopup from "./ProductPopup";
import Transactions from "./Transactions";
const headersMap = {
  users: {
    id: "Customer App ID",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    "baseSalon": "Base Salon Name", // Adjust for nested object
    isConsentAllowed: "Consent Allowed",
    referredBy: "Referred By",
    referralLevel: "Referral Level",
    "salonGuestId": "Zenoti ID",
    "customerId": "Shopify ID",
    "customerAddress": "Shipping Address",
    "customer.addresse": "Billing Address",
    "lastVisitDate":"Last Salon Visit Date",
  },
  banners: {
    id: "ID",
    caption: "Caption",
    title: "Title",
    type: "Type",
    createdAt: "Upload Date",
    goLiveDateTime: "Go Live Date & Time",
    isLive: "Is Live",
    // isActive: "Is Active",
    "action.dateTime": "Last Action Date & Time",
  },
  blogs: {
    id: "ID",
    headline: "Headline",
    content: "Content",
    createdAt: "Upload Date",
    "category.name": "Category Name",
    "document.url": " Blog Image Url",
    readingTime: "Reading Time",
    isActive: "Is Active",
    totalListens: "Total Listens",
    totalShares: "Total Shares",
    deleted: "Deleted",
    deactivatedAt: "Deleted At",
    status: "Status",
    createdAt: "Publish Date & Time",
    updatedAt: "Updated Date",
  },
  "privacy-policies": {
    id: "ID",
    title: "Title",
    caption: "Caption",
    // createdAt: "Upload Date",
    goLiveDateTime: "Go Live Date & Time",
    isActive: "Is Active",
    deactivatedAt: "Deleted At",
    updatedAt: "Last Action Date & Time",
    "actionDateTime": "Action Time",
  },
  notifications: {
    id: "Notification ID",
    title: "Title",
    message: "Message",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  socialPosts: {
    id: "ID",
    url: "Image Location",
    caption: "Caption",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  salonHomePages: {
    id: "Salon ID",
    name: "Name",
    description: "Caption",
    images: "Salon Pics",
    users: "Users",
    averageRating: "Average Rating",
    latitude: "Latitude",
    longitude: "Longitude",
    "addressInfo.address1": "Address",
    "addressInfo.zipcode": "Pincode",
    "addressInfo.city": "City",
    salonGuestAppointments: "Booking IDs",
    cancelledBookings: "Cancelled Bookings",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  serviceBooking: {
    id: "ID",
    guestName: "Guest Name",
    appointmentId: "Appointment ID",
    guestAppointmentId: "Guest Appointment ID",
    duration: "Time",
    therapistId: "Expert ID",
    status: "Status",
    totalAmount: "Amount",
    "guestAppointment.isRescheduled": "Is Rescheduled",
    invoiceItemId: "Invoice ID",
    // previousBookingId: "Previous Booking ID",
    "guestAppointment.previousBookingId": "Previous Booking ID",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  completedServiceBooking: {
    guestId: "Guest ID",
    appointmentId: "Booking ID",
    salonId: "Salon ID",
    "guestAppointment.guestAppointmentInvoice.receiptNumber": "Receipt Number",
    "rating.recommendationRating": "Recommendation Rating",
    "rating.eightStepConsultationRating": "Eight Step Consultation Rating",
    "rating.productRating": "Product Rating",
    "rating.serviceRating": "Service Rating",
    "rating.expertRating": "Expert Rating",
    "rating.averageRating": "Average Rating",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  upcomingServiceBooking: {
    appointmentId: "Booking ID",
    "guestAppointment.guestAppointmentInvoice.invoiceId": "Invoice Number",
    "guestAppointment.guestAppointmentInvoice.receiptNumber": "Receipt Number",
    createdAt: "Created At",
    updatedAt: "Updated At",
  },
  posts: {
    id: "Post ID",
    code: "Code",
    description: "Description",
    caption: "Caption",
    blockedStatus: "Blocked Status",
    blockedDateTime: "Blocked Date & Time",
    featuredStatus: "Featured Status",
    featuredDateTime: "Featured Date & Time",
    "postReactions.reaction": "Reaction",
    "_count.postReactions": "Reaction Count",
    totalShares: "Total Shares",
    isUnderReview: "Is Under Review",
    "documents.url": "Image Location",
  },
  polls: {
    id: "Poll ID",
    description: "Description",
    "pollOptions.option": "Poll Options",
    blockedStatus: "Blocked Status",
    blockedDateTime: "Blocked Date & Time",
    featuredStatus: "Featured Status",
    featuredDateTime: "Featured Date & Time",
  },
  ProductsBooking: {
    orderId: "Order ID",
    total: "Order Total",
    tax: "Order Tax",
    orderNumber: "Order Number",
    discount: "Order Discount",
    shippingAddress: "Order Shipping Address",
    orderProducts: "Order Products",
    transactions: "Transactions",
    customer: "Customer",
    // cart: "Cart",
  },
};

export const mapHeaders = (reportType) => {
  return headersMap[reportType] || {}; // Return headers as an object for the given report type
};

// renderRowData.js

export const renderRowData = (item, reportType) => {
  switch (reportType) {
    case "users":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.firstName}</td>
          <td>{item?.lastName}</td>
          <td>{item?.gender}</td>
          <td>{item?.baseSalon}</td>
          <td>{item?.isConsentAllowed ? "Yes" : "No"}</td>
          <td>{item?.referredBy ? item?.referredBy : "N/A"}</td>
          <td style={{ textAlign: "center" }}>{item?.referralLevel}</td>
          <td>{item?.salonGuestId}</td>
          <td>{item?.customerId}</td>
          <td>
            {item?.customerAddress? (
              <div>
                <div><strong>First Name:</strong> {item.customerAddress?.firstName}</div>
                <div><strong>Last Name:</strong> {item.customerAddress?.lastName}</div>
                <div><strong>Address 1:</strong> {item.customerAddress?.address1}</div>
                <div><strong>Address 2:</strong> {item.customerAddress?.address2}</div>
                <div><strong>City:</strong> {item.customerAddress?.city}</div>
                <div><strong>Province:</strong> {item.customerAddress?.province}</div>
                <div><strong>Country:</strong> {item.customerAddress?.country}</div>
                <div><strong>Zip:</strong> {item.customerAddress?.zip}</div>
                <div><strong>Phone Number:</strong> {item.customerAddress?.phoneNumber}</div>
              </div>
            ) : (
              "No Address Available"
            )}
          </td>
          <td>
            {item?.customerAddress ? (
              <div>
                <div><strong>First Name:</strong> {item.customerAddress?.firstName}</div>
                <div><strong>Last Name:</strong> {item.customerAddress?.lastName}</div>
                <div><strong>Address 1:</strong> {item.customerAddress?.address1}</div>
                <div><strong>Address 2:</strong> {item.customerAddress?.address2}</div>
                <div><strong>City:</strong> {item.customerAddress?.city}</div>
                <div><strong>Province:</strong> {item.customerAddress?.province}</div>
                <div><strong>Country:</strong> {item.customerAddress?.country}</div>
                <div><strong>Zip:</strong> {item.customerAddress?.zip}</div>
                <div><strong>Phone Number:</strong> {item.customerAddress?.phoneNumber}</div>
              </div>
            ) : (
              "No Address Available"
            )}
          </td>
          <td>{item?.lastVisitDate ? new Date(item.lastVisitDate).toLocaleDateString() : 'N/A'}</td>
        </>
      );
    
    case "banners":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.caption}</td>
          <td>{item?.title}</td>
          <td>{item?.type}</td>
          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.goLiveDateTime).toLocaleString()}</td>
          {/* <td>{item?.isLive ? "Yes" : "No"}</td> */}
          <td>{item?.isActive ? "Yes" : "No"}</td>
          <td>{item?.action?.dateTime}</td>
        </>
      );
    case "blogs":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.headline}</td>
          <td>{item?.content}</td>
          <td>{item.createdAt}</td>
          <td>{item?.category?.name}</td>
          <td>{item?.document?.url}</td>
          <td>{item?.readingTime}</td>
          <td>{item?.isActive ? "Active" : "Inactive"}</td>
          <td>{item?.totalListens}</td>
          <td>{item?.totalShares}</td>
          <td>{item?.deleted ? "Yes" : "No"}</td>
          <td style={{ textAlign: "center" }}>
  {item?.deactivatedAt
    ? new Date(item.deactivatedAt).toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    : "-"}
</td>

          <td>{item?.status}</td>
          <td>{item.updatedAt}</td>
        </>
      );
    case "privacy-policies":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.title}</td>
          <td>{item?.caption}</td>
          {/* <td>{new Date(item?.createdAt).toLocaleString()}</td> */}
          <td>{item?.goLiveDateTime}</td>
          <td>{item?.isActive ? "Active" : "Inactive"}</td>
          <td style={{ textAlign: "center" }}>
          {item?.deactivatedAt
    ? new Date(item.deactivatedAt).toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    : "-"}</td>
          <td>{item?.updatedAt}</td>
          <td>{item?.actionDateTime}</td>
        </>
      );
    case "notifications":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.title}</td>
          <td>{item?.message}</td>
          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.updatedAt).toLocaleString()}</td>
        </>
      );
      case "socialPosts":
        return (
          <>
            <td>{item?.id}</td>
            <td>
              {item?.url && <img src={item?.url} alt="Social Post Image" />}
            </td>
            {/* <td>{item?.caption}</td> */}
            <td>
              <span
                data-tooltip-id={`caption-tooltip-${item?.id}`}  // Make the id unique per item
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  maxWidth: "200px", // Adjust as needed
                  wordWrap: "break-word", // Ensures text wraps
                  overflow: "hidden", // Ensures the text doesn't overflow outside
                  textOverflow: "ellipsis", // Adds ellipsis if text overflows
                }}
              >
                {item?.caption}
              </span>
              <Tooltip
                id={`caption-tooltip-${item?.id}`}  // Make the id unique per item
                place="top"
                type="dark"
                effect="solid"
                style={{
                  wordWrap: "break-word", // Ensures long words wrap properly
                  maxWidth: "300px", // Adjust the maximum width of the tooltip
                  whiteSpace: "normal", // Allows the text to break and wrap
                }}
              >
                {item?.caption || "No Caption"}
              </Tooltip>
            </td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.updatedAt).toLocaleString()}</td>
          </>
        );
      
    case "salonHomePages":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.name}</td>
          <td>{item?.description}</td>
          <td>
            {item?.images && item?.images.length > 0 ? (
              <>
                {item?.images.slice(0, 2).map((image, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: "8px",
                      border: "1px solid white",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={image?.url}
                      alt={`Image ${index + 1}`}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                    <div>
                      <span>Image ID: {image.id}</span>
                      <br />
                      <span>
                        Created: {new Date(image.createdAt).toLocaleString()}
                      </span>
                      <br />
                      <span>
                        Updated: {new Date(image.updatedAt).toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))}
                {item?.images.length > 2 && (
                  <span>+{item?.images.length - 2} more</span>
                )}
              </>
            ) : (
              "No Images"
            )}
          </td>
          <td>
            {item?.users?.length > 0
              ? item?.users
                  .slice(0, 3) // Limit to the first 4 users
                  .map((user) => `${user.firstName} ${user.lastName}`)
                  .join(", ") + (item?.users.length > 3 ? " and more" : "") // Add "and more" if there are more than 4 users)
              : "No user exists"}
          </td>
          <td style={{ textAlign: "center" }}>{item?.averageRating}</td>
          <td style={{ textAlign: "center" }}>{item?.latitude}</td>
          <td style={{ textAlign: "center" }}>{item?.longitude}</td>
          <td
            style={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {item?.addressInfo?.addressLine1}
          </td>
          <td style={{ textAlign: "center" }}>{item?.addressInfo?.zipcode}</td>
          <td>{item?.addressInfo?.city}</td>
          <td>
            {item?.salonGuestAppointments?.length
              ? item.salonGuestAppointments
                  .slice(0, 3)
                  .map((appointment, index) => (
                    <span key={index}>
                      {appointment.appointmentGroupId}
                      <br />
                    </span>
                  ))
              : "No Appointments"}
            {item?.salonGuestAppointments?.length > 3 && (
              <span>+{item?.salonGuestAppointments?.length - 3} more</span>
            )}
          </td>
          <td style={{ textAlign: "center" }}>{item?.cancelledBookings}</td>

          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.updatedAt).toLocaleString()}</td>
        </>
      );
    case "posts":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.code}</td>
          <td>{item?.description}</td>
          <td>{item?.caption}</td>
          <td>{item?.blockedStatus ? "Yes" : "No"}</td>
          <td>
            {item?.blockedDateTime
              ? new Date(item?.blockedDateTime).toLocaleString()
              : "N/A"}
          </td>
          <td>{item?.featuredStatus ? "Yes" : "No"}</td>
          <td>
            {item?.featuredDateTime
              ? new Date(item?.featuredDateTime).toLocaleString()
              : "N/A"}
          </td>
          <td>{item?.postReactions?.join(", ") || "No Reactions"}</td>
          <td>{item?._count?.postReactions || 0}</td>
          <td>{item?.totalShares || 0}</td>
          <td>{item?.isUnderReview ? "Yes" : "No"}</td>
          <td>{item?.documents?.join(", ") || "No Documents"}</td>
        </>
      );
    case "polls":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.description}</td>
          <td>
            {item?.pollOptions?.map((option, index) => (
              <span key={index}>
                {option.option}
                {index < item.pollOptions.length - 1 ? ", " : ""}
              </span>
            )) || "No Options"}
          </td>
          <td>{item?.blockedStatus ? "Yes" : "No"}</td>
          <td>
            {item?.blockedDateTime
              ? new Date(item?.blockedDateTime).toLocaleString()
              : "N/A"}
          </td>
          <td>{item?.featuredStatus ? "Yes" : "No"}</td>
          <td>
            {item?.featuredDateTime
              ? new Date(item?.featuredDateTime).toLocaleString()
              : "N/A"}
          </td>
        </>
      );
    case "ProductsBooking":
      return (
        <>
          <td>{item?.orderId || "N/A"}</td>
          <td>{item?.total || "N/A"}</td>
          <td>{item?.tax || "N/A"}</td>
          <td>{item?.orderNumber || "N/A"}</td>
          <td>{item?.discount || "N/A"}</td>

          <td>
            <div>
              <strong>Name:</strong> {item?.shippingAddress?.name || "N/A"}
            </div>
            <div>
              <strong>Address 1:</strong>{" "}
              {item?.shippingAddress?.address1 || "N/A"}
            </div>
            <div>
              <strong>Address 2:</strong>{" "}
              {item?.shippingAddress?.address2 || "N/A"}
            </div>
            <div>
              <strong>City:</strong> {item?.shippingAddress?.city || "N/A"}
            </div>
            <div>
              <strong>Province:</strong>{" "}
              {item?.shippingAddress?.province || "N/A"}
            </div>
            <div>
              <strong>Country:</strong>{" "}
              {item?.shippingAddress?.country || "N/A"}
            </div>
            <div>
              <strong>Zip Code:</strong> {item?.shippingAddress?.zip || "N/A"}
            </div>
          </td>

          {/* Order Products Column */}
          <td>
            {item?.orderProducts?.length > 0 ? (
              <ProductPopup orderProducts={item.orderProducts} />
            ) : (
              "No Products"
            )}
          </td>

          {/* Transactions Column */}
          <td>
            {item?.transactions?.length > 0 ? (
              <Transactions
                transactions={item?.transactions}
                customerInfo={item?.customer}
              />
            ) : (
              "No Transactions"
            )}
          </td>

          <td>
            <div>
              <strong>First Name:</strong> {item?.customer?.firstName || "N/A"}
            </div>
            <div>
              <strong>Last Name:</strong> {item?.customer?.lastName || "N/A"}
            </div>
            <div>
              <strong>Email:</strong> {item?.customer?.email || "N/A"}
            </div>
            <div>
              <strong>State:</strong> {item?.customer?.state || "N/A"}
            </div>
            <div>
              <strong>Last Order Number:</strong>{" "}
              {item?.customer?.lastOrderName || "N/A"}
            </div>
            <div>
              <strong>Phone Code</strong>{" "}
              {item?.customer?.phoneNumberCode || "N/A"}
            </div>
            <div>
              <strong>Phone Number:</strong>{" "}
              {item?.customer?.phoneNumber || "N/A"}
            </div>
            <div>
              <strong>Orders Count:</strong>{" "}
              {item?.customer?.ordersCount || "N/A"}
            </div>
          </td>

          {/* Check if cart exists and has items */}
          {/* <td>
            {item?.cart?.length > 0 ? (
              <Cart transactions={item?.cart} />
            ) : (
              "No Carts"
            )}
          </td> */}
        </>
      );
    case "serviceBooking":
      return (
        <>
          <td>{item?.id}</td>
          <td>{item?.guestName ? item?.guestName : "N/A" }</td>
          <td>{item?.appointmentId}</td>
          <td>{item?.guestAppointmentId}</td>
          <td>{item?.duration} min</td>
          <td>{item?.therapistId}</td>
          <td>{item?.status}</td>
          <td>₹{item?.totalAmount}</td>
          <td>{item?.guestAppointment?.isRescheduled ? "Yes" : "No"}</td>
          <td>{item?.invoiceItemId}</td>
          {item?.guestAppointment?.isRescheduled ? (
            <td>{item?.guestAppointment?.previousBookingId || "N/A"}</td>
          ) : (
            <td>N/A</td>
          )}
          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.updatedAt).toLocaleString()}</td>
        </>
      );
    case "completedServiceBooking":
      return (
        <>
          <td>{item?.guestId}</td>
          <td>{item?.appointmentId}</td>
          <td>{item?.salonId}</td>
          <td style={{textAlign :'center'}}>
            {item?.guestAppointment?.guestAppointmentInvoice?.receiptNumber?? 'NA'}
          </td>
          <td style={{textAlign :'center'}} >{item?.rating?.recommendationRating ?? "NA"}</td>
          <td style={{textAlign :'center'}} >{item?.rating?.eightStepConsultationRating ?? "NA"}</td>
          <td style={{textAlign :'center'}} >{item?.rating?.productRating ?? "NA"}</td>
          <td style={{textAlign :'center'}} >{item?.rating?.serviceRating ?? "NA"}</td>
          <td style={{textAlign :'center'}} >{item?.rating?.expertRating ?? "NA"}</td>
          <td style={{textAlign :'center'}} >{item?.rating?.averageRating ?? "NA"}</td>
          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.updatedAt).toLocaleString()}</td>
        </>
      );
    case "upcomingServiceBooking":
      return (
        <>
          <td>{item?.appointmentId}</td>
          <td>{item?.guestAppointment?.guestAppointmentInvoice?.invoiceId}</td>
          <td>
            {item?.guestAppointment?.guestAppointmentInvoice?.receiptNumber ??
              "N/A"}
          </td>

          <td>{new Date(item?.createdAt).toLocaleString()}</td>
          <td>{new Date(item?.updatedAt).toLocaleString()}</td>
        </>
      );
  }
};

export const flattenProductsBooking = (obj, prefix = "") => {
  let result = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (
        key === "shippingAddress" &&
        typeof obj[key] === "object" &&
        obj[key] !== null
      ) {
        // Format shippingAddress
        result[newKey] = Object.entries(obj[key])
          .map(([subKey, subValue]) => `${subKey}: ${subValue || "N/A"}`)
          .join(", ");
      } else if (
        key === "customer" &&
        typeof obj[key] === "object" &&
        obj[key] !== null
      ) {
        // Format customer
        result[newKey] = Object.entries(obj[key])
          .map(([subKey, subValue]) => `${subKey}: ${subValue || "N/A"}`)
          .join(", ");
      } else if (key === "transactions" && Array.isArray(obj[key])) {
        // Format transactions with line breaks between transactions
        result[newKey] = obj[key]
          .map((transaction, index) => {
            return `Transaction ${index + 1}: ${Object.entries(transaction)
              .map(([subKey, subValue]) =>
                typeof subValue === "object" && subValue !== null
                  ? `${subKey}: ${flattenProductsBooking(subValue)}`
                  : `${subKey}: ${subValue || "N/A"}`
              )
              .join(", ")}`; // Join transaction properties with comma
          })
          .join("\n"); // Join transactions with single newline
      } else if (key === "orderProducts" && Array.isArray(obj[key])) {
        // Format orderProducts (keeping comma separation)
        result[newKey] = obj[key]
          .map((product, index) => {
            return `Product ${index + 1}: ${Object.entries(product)
              .map(([subKey, subValue]) =>
                typeof subValue === "object" && subValue !== null
                  ? `${subKey}: ${flattenProductsBooking(subValue)}`
                  : `${subKey}: ${subValue || "N/A"}`
              )
              .join(", ")}`; // Join product properties with comma
          })
          .join(" | "); // Join products with pipe
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively flatten nested objects
        Object.assign(result, flattenProductsBooking(obj[key], newKey));
      } else {
        // Handle primitive values
        result[newKey] =
          obj[key] !== undefined && obj[key] !== null ? obj[key] : "N/A";
      }
    }
  }

  return result;
};

export const flattenObject = (obj, prefix = "") => {
  let result = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Flatten nested object and join the address fields into one string
        if (newKey === "customerAddress") {
          result[newKey] = `${obj[key]?.firstName || ""} ${obj[key]?.lastName || ""}, ${obj[key]?.address1 || ""}, ${obj[key]?.address2 || ""}, ${obj[key]?.city || ""}, ${obj[key]?.province || ""}, ${obj[key]?.country || ""}, ${obj[key]?.zip || ""}, ${obj[key]?.phoneNumber || ""}`;
        } else {
          Object.assign(result, flattenObject(obj[key], newKey)); // Recursively flatten other objects
        }
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
};

